<template>
<div class="tw-flex tw-w-auto lg:tw-items-center tw-flex-row tw-justify-end" v-if="oneWayBookingShowTimer">
  <img src="@/assets/countdown.svg" alt="count down" style="height: 18px; width: 18px">
  <div class="count">
    <span class="tw-pl-3">Complete your booking in less than
      {{ oneWayBookingMinutesTimer }} mins : {{secondsTimer}} secs
    </span>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "SeatTimer",
  props:{
    tripId: [Number]
  },
  data(){
    return{
      minutesTimer : 0,
      secondsTimer: 0

    }
  },
  computed:{
    ...mapGetters('companyProfile',['companyData']),
    ...mapGetters('seatsSelection',['oneWayBookingMinutesTimer','oneWayBookingShowTimer'])
  },
  watch: {
    secondsTimer: {
      async handler(value) {
        let minutesTimer = 0
        let oneWayBookingMinutesTimer = sessionStorage.getItem('oneWayBookingMinutesTimer')
        let minusFromTimer = JSON.parse(sessionStorage.getItem('minusFromTimer'))
        minutesTimer = Number.parseInt(oneWayBookingMinutesTimer)

        if (value > 0) {
          setTimeout(() => {
            this.secondsTimer--;
          }, 1000);
        } else if (value === 0 && minutesTimer > 0 || this.oneWayBookingMinutesTimer) {
          if(!minusFromTimer){
            sessionStorage.setItem('minusFromTimer',JSON.stringify( true))
          }
          else {
            minutesTimer--
          }
          console.log(minutesTimer)
          sessionStorage.setItem('oneWayBookingMinutesTimer',JSON.stringify(minutesTimer))
          await this.$store.dispatch('seatsSelection/setOneWayBookingMinutesTimer', minutesTimer)
          this.secondsTimer = 60

        } else if (  value === 0 && minutesTimer === 0 ) {
          let passengerDetail = JSON.parse(sessionStorage.getItem('passengerDetail'))
          if (passengerDetail){
            passengerDetail.seats = []
            sessionStorage.setItem('passengerDetail', JSON.stringify(passengerDetail))
            sessionStorage.setItem("bookingComponent", "addBooking");
            await this.$store.dispatch("bookingComponent/setCurrentComponent", "addBooking");
            if (this.oneWayBookingShowTimer) {
              await this.$store.dispatch('seatsSelection/setOneWayBookingMinutesTimer', minutesTimer)
              await this.$store.dispatch('seatsSelection//setOneWayBookingShowTimer', false)
              sessionStorage.removeItem('oneWayBookingMinutesTimer')
              sessionStorage.removeItem('oneWayBookingShowTimer')
              sessionStorage.removeItem('currentSelectedSeats')
              sessionStorage.removeItem("tripDetails")
              sessionStorage.removeItem("passengerDetail")
              window.location.reload()
            }
          }
        }
         this.$nextTick(()=>{minutesTimer = Number.parseInt(oneWayBookingMinutesTimer)})
      },
      immediate: true,
    }

  },
  created() {
     let oneWayBookingMinutesTimer = sessionStorage.getItem('oneWayBookingMinutesTimer')
     let oneWayBookingShowTimer =  sessionStorage.getItem('oneWayBookingShowTimer')
    if (oneWayBookingMinutesTimer){
      this.$store.dispatch('seatsSelection/setOneWayBookingMinutesTimer',JSON.parse(oneWayBookingMinutesTimer))
    }
    if (oneWayBookingShowTimer){
      this.$store.dispatch('seatsSelection/setOneWayBookingShowTimer',JSON.parse(oneWayBookingShowTimer))
    }
  }
}
</script>

<style scoped lang="scss">
.count{
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #D3766A;
}
</style>