<script >

import PhoneNumber from "../../reuseables/PhoneNumber.vue";
import BookingCodeModal from "@/components/reuseables/booking/BookingCodeModal";
import {mapGetters} from "vuex";
import {getPassengerDetail} from "../../../services/api/APIService";

export default {
  name:"NewTravellerDetail",
  components: {PhoneNumber,BookingCodeModal},
  props:{
    index: [Number],
    travellerDetail:{}
  },
  data(){
    return{
      traveller:{},
      dialog:false,
      getCodeLoading:false
    }
  },
  watch:{
    traveller: {
      handler : function (val) {
        if (this.index === 0) {
          sessionStorage.setItem("primaryTraveller",JSON.stringify(val))
        }
        this.tripAndTravellerDetails.seats.forEach((seat,index) => {
          if (index === this.index){
            seat.travellerDetail = val
          }
        })
        this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",this.tripAndTravellerDetails)
        sessionStorage.setItem("tripDetails",JSON.stringify(this.tripAndTravellerDetails))
        console.log("------------")
      },
      immediate:true,
      deep:true
    },

  },
  computed:{
    ...mapGetters("companyProfile",["companyData"]),
    ...mapGetters("tripAndTravellerDetails",["tripAndTravellerDetails"])
  },
  methods:{
    getNextOfKinNumber(number) {
      this.traveller.nextOfKinPhoneNumber = number
    },
    getTravellerNumber(number) {
      this.traveller.phoneNumber = number
    },
    closeModal(){
      this.dialog = !this.dialog
    },
    toSentenceCase(text){
      text = text.toLowerCase()
      return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
    },
    getPrimaryTraveller(){
      let primaryTraveller = JSON.parse(sessionStorage.getItem("primaryTraveller"))
      if (Object.keys(primaryTraveller).length){
        this.traveller = primaryTraveller
      }
      else {
        this.$displaySnackbar({
          message: "Please fill in primary traveller's details",
          success: false,
        });
      }
    },
    async  enterBookingCode(code) {
      this.getCodeLoading = true
      if (code && code.length === 10){
        await getPassengerDetail(code).then(res =>{
          if (res.data.address){
            res.data.address.country = this.toSentenceCase(res.data.address.country)
            res.data.address.state = this.toSentenceCase(res.data.address.state)
            res.data.address.city = this.toSentenceCase(res.data.address.city)
            res.data.address.streetName = this.toSentenceCase(res.data.address.streetName)
          }
          this.traveller = res.data
          this.getCodeLoading = false
          this.closeModal()
        }).catch((err) => {
              this.getCodeLoading = false
              console.log(err.response);
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });
            });
      }
      else {
        this.getCodeLoading = false
        this.$displaySnackbar({
          message: "Not a valid code",
          success: false,
        });
      }

    },
  }
}
</script>

<template>
<div class="tw-flex tw-w-full tw-flex-col">
    <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between tw-items-start lg:tw-items-center tw-py-5">
      <p :style="{color: companyData.companyColor ? companyData.companyColor : '#004AAD'}"
         class="passenger">Passenger <span v-if="index > 0" class="or"> {{index+1}} </span>  Details</p>
      <p  class="tw-pt-8 md:tw-pt-0"><span :style="{color: companyData.companyColor ? companyData.companyColor : '#004AAD'}"
                                           class="use-code tw-cursor-pointer" @click="closeModal">Use Traveller’s Code </span><span v-if="index > 0" class="or"> OR </span>
        <span v-if="index > 0" :style="{color: companyData.companyColor ? companyData.companyColor : '#004AAD'}"
              class="use-code tw-cursor-pointer" @click="getPrimaryTraveller">Use Primary Details</span></p>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-py-5">
      <ValidationProvider name="First name" rules="required" v-slot="{classes, errors}">
        <div :class="classes">
      <v-text-field placeholder="First Name" hide-details solo v-model="traveller.firstName"></v-text-field>
          <span> {{errors[0]}} </span>
        </div>
      </ValidationProvider>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <div v-else class="tw-my-5"></div>
      <ValidationProvider name="Last name" rules="" v-slot="{classes, errors}">
        <div :class="classes">
         <v-text-field placeholder="Last Name" hide-details solo v-model="traveller.lastName"></v-text-field>
          <span> {{errors[0]}} </span>
        </div>
      </ValidationProvider>
    </div>
    <div class="tw-flex tw-w-full tw-flex-row tw-py-5 tw-w-full">
      <phone-number :phone-number-exist="traveller.phoneNumber" @getNumber="getTravellerNumber" />
    </div>
    <div class="tw-flex tw-w-full tw-flex-row tw-py-5">
      <label class="trip-item text-capitalize">Next of Kin Details</label>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-py-5">
      <ValidationProvider name="Next Of Kin First name" rules="" v-slot="{classes, errors}">
        <div :class="classes">
          <v-text-field placeholder="First Name" hide-details solo v-model="traveller.nextOfKinFirstName"></v-text-field>
          <span> {{errors[0]}} </span>
        </div>
      </ValidationProvider>
      <v-spacer  v-if="$vuetify.breakpoint.mdAndUp" ></v-spacer>
      <div v-else class="tw-my-5"></div>
      <ValidationProvider name="Next Of Kin Last name" rules="" v-slot="{classes, errors}">
        <div :class="classes">
      <v-text-field placeholder="Last Name" hide-details solo v-model="traveller.nextOfKinLastName"></v-text-field>
          <span> {{errors[0]}} </span>
        </div>
      </ValidationProvider>
    </div>
    <div class="tw-flex tw-w-full tw-flex-row tw-py-5 tw-w-full">
      <phone-number :phone-number-exist="traveller.nextOfKinPhoneNumber" @getNumber="getNextOfKinNumber" />
    </div>
  <booking-code-modal
      :dialog="dialog"
      @close="closeModal"
      @bookingAction="enterBookingCode"
      :doneLoading="getCodeLoading"/>
</div>
</template>

<style scoped lang="scss">
.trip-item{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px;
  color: #4F4F4F;
}
</style>