<template>
  <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-pt-0 lg:tw-px-0 lg:tw-pt-0 tw-items-center tw-justify-center tw-w-auto">
    <div class="seat-panel  tw-p-5 " :style="{minWidth : totalSeats.length > 18 ? '400px' : '320px'}">
      <div class="tw-flex tw-w-full  tw-flex-row tw-mb-8">
        <img :src="steering" alt="booked" class="tw-flex tw-items-end" style="width: 50px; height: 50px" />
        <v-spacer/>
        <seat  :seat-number="totalSeats[0].seatNumber ? totalSeats[0].seatNumber : 0" :seat-status="totalSeats[1].status"
               class="tw-flex tw-items-start" @selectedSeat="handleSelectedSeats" />
      </div>

      <div v-if="totalSeats.length === 6" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 6)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column  :seats-data="getSeatByRow('secondRow', 6)"
                      @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 7" class="tw-flex tw-w-full tw-flex-col">
        <seat-column :single-space="true"  :seats-data="getSeatByRow('firstRow', 7)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true"  :seats-data="getSeatByRow('secondRow', 7)"
                     @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 8" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 8)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column  :seats-data="getSeatByRow('secondRow', 8)"
                      @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 9" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 9)"
                      @selectedSeat="handleSelectedSeats"/>
        <back-seat-column  :seats-data="getSeatByRow('backSeat')"
                           @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 12" class="tw-flex tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 12)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('secondRow', 12)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')" @selectedSeat="handleSelectedSeats" />
      </div>

      <div v-if="totalSeats.length === 14" class="tw-flex  tw-w-full tw-flex-col ">
        <seat-column  :seats-data="getSeatByRow('firstRow', 14)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('secondRow', 14)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('thirdRow', 14)"
                     v-if="totalSeats.length> 12" @selectedSeat="handleSelectedSeats"/>

        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 15" class="tw-flex  tw-w-full tw-flex-col ">
        <seat-column  :seats-data="getSeatByRow('firstRow', 15)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('secondRow', 15)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('thirdRow', 15)"
                     v-if="totalSeats.length> 12" @selectedSeat="handleSelectedSeats"/>

        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 18" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 18)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('secondRow', 18)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true" :seats-data="getSeatByRow('thirdRow', 18)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :single-space="true"  :seats-data="getSeatByRow('fourthRow', 18)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 22" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fifthRow', 22)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 24" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 24)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 24)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 24)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 24)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 24)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 30" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 30)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 30)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 32" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column  :seats-data="getSeatByRow('firstRow', 32)"
                      @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 32)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 34" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 34)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 35" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 35)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat')"
                          @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 40" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 40)"
                     @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 45" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eleventhRow', 45)"
                     @selectedSeat="handleSelectedSeats"/>
      </div>

      <div v-if="totalSeats.length === 50" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eleventhRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('twelfthRow', 50)"
                     @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length === 52" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eleventhRow', 52)"
                     @selectedSeat="handleSelectedSeats"/>
        <back-seat-column :seats-data="getSeatByRow('backSeat',52)"
                          @selectedSeat="handleSelectedSeats"/>
      </div>
      <div v-if="totalSeats.length >= 53" class="tw-flex  tw-w-full tw-flex-col">
        <seat-column :double-space="true" :seats-data="getSeatByRow('firstRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('secondRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true" :seats-data="getSeatByRow('thirdRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"   :seats-data="getSeatByRow('fourthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('fifthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('sixthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('seventhRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eighthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('ninthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('tenthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('eleventhRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        <seat-column :double-space="true"  :seats-data="getSeatByRow('twelfthRow', 53)"
                     @selectedSeat="handleSelectedSeats"/>
        </div>
    </div>

    <div class="tw-flex tw-w-full lg:tw-w-5/12 tw-flex-col tw-py-10 lg:tw-p-10 tw-pl-5  ">
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-mb-5">
        <img :src="availableSeat" alt="booked" />
        <label class="trip-item tw-ml-10">Available Seat(s)</label>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-mb-5">
        <img :src="selectedSeat" alt="selected" />
        <label class="trip-item tw-ml-10">Selected Seat(s)</label>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-mb-5">
        <img :src="pendingSeat" alt="selected" />
        <label class="trip-item tw-ml-10">Pending Seat(s) [Available in less than 10Mins]</label>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
        <img :src="bookedSeat" alt="available" />
        <label class="trip-item tw-ml-10">Booked Seat(s)</label>
      </div>
    </div>

  </div>
</template>

<script>
import camera from '@/assets/camera.png'
import availableSeat from '@/assets/booked.svg'
import selectedSeat from '@/assets/selected.svg'
import bookedSeat from '@/assets/available.svg'
import pendingSeat from '@/assets/pendingSeat.svg'
import steering from '@/assets/steering.svg'
import Seat from "@/components/transportCompany/booking/Seat";
import BackSeatColumn from "@/components/transportCompany/booking/BackSeatColumn";
import SeatColumn from "@/components/transportCompany/booking/SeatColumn";
export default {
  name: "VehicleSeats",
  components: {SeatColumn, BackSeatColumn, Seat},
  props:{
    trip:Object
  },
  data(){
    return{
      camera,
      availableSeat,
      selectedSeat,
      pendingSeat,
      bookedSeat : bookedSeat,
      steering :steering,

      totalSeats:[],
      selectedSeats :[],

    }
  },
  watch:{
    trip:{
      handler : function (val){
        if (val && val.fleetForThisTrip && val.fleetForThisTrip.seats){
          this.availableSeats = []
          val.fleetForThisTrip.seats.forEach(seat => {
            if (seat.status === 'AVAILABLE'){
              this.availableSeats.push(seat)
            }
            this.totalSeats = val.fleetForThisTrip.seats
          })
        }
      },
      immediate: true,
      deep : true
    }
  },
  methods:{
    handleSelectedSeats(clickSeat){
      let tripAndSeatsDetail = {}
      tripAndSeatsDetail.tripId = this.trip.id
      tripAndSeatsDetail.seats = clickSeat
      tripAndSeatsDetail.tripAmount = this.trip.tripAmount

      this.trip.fleetForThisTrip.seats.forEach(seat => {
        if (seat.seatNumber === clickSeat.seatNumber){
          seat.seatstatus = clickSeat.seatStatus
        }
      })
      this.$store.dispatch("seatsSelection/getTotalAmount",this.trip.tripAmount)
      this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",tripAndSeatsDetail)
      sessionStorage.setItem("currentSelectedSeats",JSON.stringify(tripAndSeatsDetail))
      this.$emit('getTripAndSeatsDetails',tripAndSeatsDetail)

    },
    getSeatByRow(rowType, seater){
      let rowData = [];
      // siena starts
      if (rowType === "firstRow" && seater === 6){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) > 1 && Number.parseInt(seat.seatNumber) < 4){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 6){
        this.totalSeats.forEach(seat =>{
          if (Number.parseInt(seat.seatNumber) >3 && Number.parseInt(seat.seatNumber) < 7){
            rowData.push(seat)
          }
        })
      }
      // siena starts 7
      if (rowType === "firstRow" && seater === 7){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 4){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 7){
        this.totalSeats.forEach(seat =>{

          if (seat.seatNumber >3 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      if (rowType === "firstRow" && seater === 8){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 8){
        this.totalSeats.forEach(seat =>{

          if (seat.seatNumber >4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }

      if (rowType === "firstRow" && seater === 9){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 6){
            rowData.push(seat)
          }
        })
      }
          // siena ends

      // 12 seater starts
      else if (rowType === "firstRow" && seater > 11 && seater <14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater > 11 && seater <14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater > 11 && seater <14  ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >7 && seat.seatNumber < 12){
            rowData.push(seat)
          }
        })
      }
          // 12 seater ends

      // 14 seater start
      else if (rowType === "firstRow" && seater === 14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 14 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
          // 14 seater ends

      // 15 seater starts
      else if (rowType === "firstRow" && seater === 15 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 15 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 15 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >7 && seat.seatNumber < 12){
            rowData.push(seat)
          }
        })
      }
          // 15 seater ends
      // 18 seater starts
      else if (rowType === "firstRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 18 ){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber >10 && seat.seatNumber < 15){
            rowData.push(seat)
          }
        })
      }
          // 18 seater ends
      // 22 seater start
      else if (rowType === "firstRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 10 && seat.seatNumber < 15){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 22 && this.totalSeats.length >= 22){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 14 && seat.seatNumber < 19){
            rowData.push(seat)
          }
        })
      }

          // 22 seater ends
      // 24 seater starts
      else if (rowType === "firstRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 24 && this.totalSeats.length >= 24){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
          // 24 seater ends
      // 30 seater starts
      else if (rowType === "firstRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 10 && seat.seatNumber < 15){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 14 && seat.seatNumber < 19){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 18 && seat.seatNumber < 23){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 30 && this.totalSeats.length >= 30){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 22 && seat.seatNumber < 27){
            rowData.push(seat)
          }
        })
      }

          // 30 seater ends
      // 32 seater starts
      else if (rowType === "firstRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 25){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 32 && this.totalSeats.length >= 32){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 24 && seat.seatNumber < 29){
            rowData.push(seat)
          }
        })
      }
          // 32 seater ends
      // 34 seater starts
      else if (rowType === "firstRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 7 && seat.seatNumber < 11){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 10 && seat.seatNumber < 15){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 14 && seat.seatNumber < 19){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 18 && seat.seatNumber < 23){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 22 && seat.seatNumber < 27){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 34 && this.totalSeats.length >= 34){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 26 && seat.seatNumber < 31){
            rowData.push(seat)
          }
        })
      }
          // 34 seater ends
      // 35 seater starts
      else if (rowType === "firstRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 8){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 7 && seat.seatNumber < 12){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 11 && seat.seatNumber < 16){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 15 && seat.seatNumber < 20){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 19 && seat.seatNumber < 24){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 23 && seat.seatNumber < 28){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 35 && this.totalSeats.length >= 35){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 27 && seat.seatNumber < 32){
            rowData.push(seat)
          }
        })
      }
          // 35 ends

      // 40 starts
      else if (rowType === "firstRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 26){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 25 && seat.seatNumber < 31){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 30 && seat.seatNumber < 36){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 40 && this.totalSeats.length >= 40){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 35 && seat.seatNumber < 41){
            rowData.push(seat)
          }
        })
      }
          // 40 ends

      // 45 starts
      else if (rowType === "firstRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 26){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 25 && seat.seatNumber < 31){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 30 && seat.seatNumber < 36){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 35 && seat.seatNumber < 41){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "tenthRow" && seater === 45 && this.totalSeats.length >= 45){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 40 && seat.seatNumber < 46){
            rowData.push(seat)
          }
        })
      }
          // 45 ends

      // 50 starts
      else if (rowType === "firstRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 26){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 25 && seat.seatNumber < 31){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 30 && seat.seatNumber < 36){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 35 && seat.seatNumber < 41){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "tenthRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 40 && seat.seatNumber < 46){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eleventhRow" && seater === 50 && this.totalSeats.length >= 50){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 45 && seat.seatNumber < 51){
            rowData.push(seat)
          }
        })
      }
          // 50 ends

      //52 starts
      else if (rowType === "firstRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 25){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 24 && seat.seatNumber < 29){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 28 && seat.seatNumber < 34){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 33 && seat.seatNumber < 39){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "tenthRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 38 && seat.seatNumber < 44){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eleventhRow" && seater === 52 && this.totalSeats.length >= 52){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 43 && seat.seatNumber < 49){
            rowData.push(seat)
          }
        })
      }
          // 52 end

      //53 starts
      else if (rowType === "firstRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 1 && seat.seatNumber < 5){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "secondRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 4 && seat.seatNumber < 9){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "thirdRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 8 && seat.seatNumber < 13){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fourthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 12 && seat.seatNumber < 17){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "fifthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 16 && seat.seatNumber < 21){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "sixthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 20 && seat.seatNumber < 25){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "seventhRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 24 && seat.seatNumber < 29){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eighthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 28 && seat.seatNumber < 34){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "ninthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 33 && seat.seatNumber < 39){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "tenthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 38 && seat.seatNumber < 44){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "eleventhRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 43 && seat.seatNumber < 49){
            rowData.push(seat)
          }
        })
      }
      else if (rowType === "twelfthRow" && seater === 53 && this.totalSeats.length >= 53){
        this.totalSeats.forEach(seat =>{
          if (seat.seatNumber > 48 && seat.seatNumber < 54){
            rowData.push(seat)
          }
        })
      }
      // 35 seater ends
      else if (rowType === "backSeat" ){

        rowData = this.totalSeats.slice(this.totalSeats.length - 4)
      }
      else if (rowType === "custom"){
        rowData = this.totalSeats
      }
      return rowData;
    },
  },
  created() {
    let currentSelectedSeats = JSON.parse(sessionStorage.getItem('currentSelectedSeats'))
    if ( currentSelectedSeats && currentSelectedSeats.seats && Object.keys(this.trip).length){
      currentSelectedSeats.seats.forEach(seat =>{
        this.trip.fleetForThisTrip.seats.forEach(seatOnTrip =>{
          if (seat.seatNumber === seatOnTrip.seatNumber && seatOnTrip.status === 'PENDING'){
            seatOnTrip.status = 'SELECTED'
          }
        })
      })
      this.$store.dispatch('seatsSelection/updateCurrentSeats',currentSelectedSeats.seats)
      this.$store.dispatch("seatsSelection/getTotalAmount",this.trip.tripAmount)
    }
    else if ( currentSelectedSeats && Object.keys(this.trip).length){
      currentSelectedSeats.forEach(seat =>{
        this.trip.fleetForThisTrip.seats.forEach(seatOnTrip =>{
          if (seat.seatNumber === seatOnTrip.seatNumber && seatOnTrip.status === 'PENDING'){
            seatOnTrip.status = 'SELECTED'
          }
        })
      })
      this.$store.dispatch("seatsSelection/updateCurrentSeats",currentSelectedSeats)
      this.$store.dispatch("seatsSelection/getTotalAmount",this.trip.tripAmount)
    }
  }
}
</script>

<style scoped lang="scss">
.seat-panel{
  background: #FDFFFC;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  min-width: 250px;
  width: 250px;
  height: max-content;

  @media screen  and (max-width: 1024px){
    margin-top: 20px;
  }
}
.column{
  display: flex;
  flex-direction: column;

}
</style>