var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn",on:{"click":_vm.selectTab}},[_c('img',{attrs:{"src":require("@/assets/icons/terminal-arrow-back.svg"),"alt":""}}),_c('span',{staticClass:"tw-ml-4"},[_vm._v("Passenger Booking Details")])]),_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 booking-btn",on:{"click":_vm.showBookingCodeModal}},[_c('span',{},[_vm._v("Enter Booking Code")])])]),_c('v-col',{staticClass:"tw-bg-white tw-mt-9 tw-rounded-xl tw-pl-16 tw-py-10 tw-pr-40 tw-w-full",attrs:{"sm":"12"}},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"},[_c('p',{staticClass:"edit-header"},[_vm._v("Edit Passenger")])]),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("First Name")]),_c('p',{staticClass:"label-info"},[_vm._v("Type the First Name of the Passenger")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Mason","solo":"","hide-details":"","type":"text","required":""}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Last Name")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Last Name of the Passenger ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Ryan","solo":"","hide-details":"","type":"text","required":""}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Gender")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose the Gender of the Passenger. ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"items":_vm.genders,"label":"Choose Gender","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","outlined":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Email")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Email Address of the Manager ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Manager's Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"ryan@gmail.com","solo":"","hide-details":"","type":"text","required":""}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Mobile")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Mobile Number ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Manager's Contact Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"+234","solo":"","hide-details":"","type":"text","required":""}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Country")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose the Country the Terminal is located. ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-select',{attrs:{"items":_vm.countries,"label":"Choose Country","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","outlined":""},on:{"change":function($event){return _vm.getState()}},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("State")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the State the Terminal is located.")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[(_vm.states.length > 0)?_c('v-select',{attrs:{"items":_vm.states,"label":"Choose State","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","disabled":_vm.getStatesLoading,"outlined":""},on:{"change":function($event){return _vm.getCity()}},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}):_c('v-text-field',{staticClass:"tw-mt-2",attrs:{"placeholder":"State","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("City")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the City the Terminal is located.")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"City/Town","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[(_vm.cities.length > 0)?_c('v-select',{attrs:{"items":_vm.cities,"append-icon":"mdi-chevron-down","disabled":_vm.getCitiesLoading,"outlined":""},on:{"change":function($event){return _vm.getCity()}},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}):_c('v-text-field',{staticClass:"tw-mt-2",attrs:{"append-icon":"mdi-chevron-down","placeholder":"City or Town","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Street Name")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose the Street Name in which the Terminal is located. ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Street's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Borno Way","solo":"","hide-details":"","type":"text","required":""}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('v-row',{staticClass:"tw-mb-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Street Number")]),_c('p',{staticClass:"label-info"},[_vm._v(" Street Number in which the Passesnger is located. ")])])]),_c('v-col',{attrs:{"sm":"8"}},[_c('ValidationProvider',{attrs:{"name":"Street Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"20","solo":"","hide-details":"","type":"text","required":""}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"},[_c('button',{staticClass:"first-btn"},[_vm._v(" Cancel ")]),_c('button',{staticClass:"second-btn",on:{"click":_vm.showModal}},[_vm._v(" Done ")])])],1),_c('booking-modal',{attrs:{"dialog":_vm.dialog,"doneLoading":_vm.deleteLoading},on:{"close":_vm.closeModal,"bookingAction":_vm.enterBookingCode}}),_c('modal',{attrs:{"dialog":_vm.showDialog,"color":_vm.companyColor,"title":"Great!","description":"Your Booking was successful. \nKindly download your ticket below.","icon":"thumbs-up","bottomText":"Click here to download","downloadUrl":"https://facebook.com"},on:{"close":_vm.closeDefaultModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }