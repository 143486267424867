var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn tw-mt-20 lg:tw-mt-0",style:({ color: _vm.companyColor }),on:{"click":_vm.back}},[_c('icons',{attrs:{"name":"arrow-back","color":_vm.companyColor}}),_c('span',{staticClass:"tw-ml-4"},[_vm._v(" Passenger Booking Details")])],1),_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-mt-10 tw-w-full"},[_c('v-divider',{attrs:{"light":""}}),_c('avatar-progress',{staticClass:"tw-ml-3",attrs:{"avatar":"1","color":_vm.companyColor}}),_c('avatar-progress',{staticClass:"tw-mx-3",attrs:{"avatar":"2","color":_vm.companyColor}}),_c('avatar-progress',{attrs:{"avatar":"3","avatar-state":true,"color":_vm.companyColor}})],1),_c('v-col',{staticClass:"tw-bg-white tw-mt-9 tw-rounded-xl tw-px-5 lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full",attrs:{"sm":"12"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.proceedToDone)}}},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"},[_c('p',{staticClass:"edit-header",style:({color:_vm.companyColor})},[_vm._v("Next of Kin")])]),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Relationship")]),_c('p',{staticClass:"label-info"},[_vm._v(" Relationship with the Passenger. ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Relationship","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"items":_vm.relationships,"placeholder":"Choose Relationship","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","solo":"","hide-details":""},model:{value:(_vm.passengerDetail.relationship),callback:function ($$v) {_vm.$set(_vm.passengerDetail, "relationship", $$v)},expression:"passengerDetail.relationship"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("First Name")]),_c('p',{staticClass:"label-info"},[_vm._v("Type the First Name of the Next of Kin")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Mason","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.passengerDetail.nextOfKinFirstName),callback:function ($$v) {_vm.$set(_vm.passengerDetail, "nextOfKinFirstName", $$v)},expression:"passengerDetail.nextOfKinFirstName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Last Name")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Last Name of the Next of Kin ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Ryan","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.passengerDetail.nextOfKinLastName),callback:function ($$v) {_vm.$set(_vm.passengerDetail, "nextOfKinLastName", $$v)},expression:"passengerDetail.nextOfKinLastName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Gender")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose Next of Kin Gender. ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"items":_vm.genders,"placeholder":"Choose Gender","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","solo":"","hide-details":""},model:{value:(_vm.passengerDetail.nextOfKinGender),callback:function ($$v) {_vm.$set(_vm.passengerDetail, "nextOfKinGender", $$v)},expression:"passengerDetail.nextOfKinGender"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Email")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Email Address of the Next of Kin ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"ryan@gmail.com","solo":"","hide-details":"","type":"email","required":""},model:{value:(_vm.passengerDetail.nextOfKinEmail),callback:function ($$v) {_vm.$set(_vm.passengerDetail, "nextOfKinEmail", $$v)},expression:"passengerDetail.nextOfKinEmail"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Mobile")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Mobile Number of the Next of Kin ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('phone-number',{attrs:{"phone-number-exist":_vm.passengerDetail.nextOfKinPhoneNumber},on:{"getNumber":_vm.getNumber}})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Country")]),_c('p',{staticClass:"label-info"},[_vm._v(" Choose the Country of the Next of Kin. ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"items":_vm.countries,"placeholder":"Choose Country","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","solo":"","hide-details":""},on:{"change":function($event){return _vm.getState()}},model:{value:(_vm.passengerDetail.nextOfKinAddress.country),callback:function ($$v) {_vm.$set(_vm.passengerDetail.nextOfKinAddress, "country", $$v)},expression:"passengerDetail.nextOfKinAddress.country"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("State")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the State of the Next of Kin.")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"items":_vm.states,"placeholder":"Choose State","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","solo":"","hide-details":""},on:{"change":function($event){return _vm.getCity()}},model:{value:(_vm.passengerDetail.nextOfKinAddress.state),callback:function ($$v) {_vm.$set(_vm.passengerDetail.nextOfKinAddress, "state", $$v)},expression:"passengerDetail.nextOfKinAddress.state"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("City")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose the City of the Next of Kin.")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"City/Town","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-combobox',{attrs:{"items":_vm.cities,"append-icon":"mdi-chevron-down","solo":"","hide-details":"","placeholder":"Choose City"},on:{"change":function($event){return _vm.getCity()}},model:{value:(_vm.passengerDetail.nextOfKinAddress.city),callback:function ($$v) {_vm.$set(_vm.passengerDetail.nextOfKinAddress, "city", $$v)},expression:"passengerDetail.nextOfKinAddress.city"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Street Name")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Street Name of the Next of Kin . ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Street's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Borno Way","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.passengerDetail.nextOfKinAddress.streetName),callback:function ($$v) {_vm.$set(_vm.passengerDetail.nextOfKinAddress, "streetName", $$v)},expression:"passengerDetail.nextOfKinAddress.streetName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("House Number")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the House Number of the Next of Kin. ")])])]),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"House Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"20","solo":"","hide-details":"","type":"Number","required":""},model:{value:(_vm.passengerDetail.nextOfKinAddress.houseNumber),callback:function ($$v) {_vm.$set(_vm.passengerDetail.nextOfKinAddress, "houseNumber", $$v)},expression:"passengerDetail.nextOfKinAddress.houseNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"},[_c('v-btn',{staticClass:"first-btn",style:({color: _vm.companyColor, border: '1px solid' + _vm.companyColor,}),attrs:{"text":""},on:{"click":_vm.backToTrip}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyColor }),attrs:{"text":"","type":"submit","loading":_vm.doneLoading}},[_vm._v(" Done ")])],1)],1)]}}])})],1),_c('modal',{attrs:{"dialog":_vm.showDialog,"color":_vm.companyColor,"title":"Great!","description":"Customer Booking was successful.\n                A copy of the reciept have been sent to your company email and customer email","icon":"thumbs-up","additional-text":"Thank you"},on:{"close":_vm.showModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }