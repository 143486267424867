<template>
  <v-col sm="12">
    <div class="tw-flex tw-justify-between tw-items-center">
      <button
        class="tw-flex tw-items-center tw-mt-6 overview-btn"
        @click="selectTab"
      >
        <img src="@/assets/icons/terminal-arrow-back.svg" alt="" />
        <span class="tw-ml-4">Passenger Booking Details</span></button
      ><button
        class="tw-flex tw-items-center tw-mt-6 booking-btn"
        @click="showBookingCodeModal"
      >
        <!-- <img src="@/assets/icons/terminal-arrow-back.svg" alt="" /> -->
        <span class="">Enter Booking Code</span>
      </button>
    </div>

    <v-col
      sm="12"
      class="tw-bg-white tw-mt-9 tw-rounded-xl tw-pl-16 tw-py-10 tw-pr-40 tw-w-full"
    >
      <div
        class="tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"
      >
        <p class="edit-header">Edit Passenger</p>
        <!-- <img src="@/assets/icons/del-blue.svg" alt="" /> -->
      </div>
      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">First Name</p>
            <p class="label-info">Type the First Name of the Passenger</p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="First Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="Mason"
                solo
                hide-details
                type="text"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Last Name</p>
            <p class="label-info">
              Type the Last Name of the Passenger
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Last Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="Ryan"
                solo
                hide-details
                type="text"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Gender</p>
            <p class="label-info">
              Choose the Gender of the Passenger.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Gender"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="genders"
                label="Choose Gender"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                v-model="gender"
                outlined
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Email</p>
            <p class="label-info">
              Type the Email Address of the Manager
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Manager's Email"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="ryan@gmail.com"
                solo
                hide-details
                type="text"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Mobile</p>
            <p class="label-info">
              Type the Mobile Number
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Manager's Contact Number"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="+234"
                solo
                hide-details
                type="text"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Country</p>
            <p class="label-info">
              Choose the Country the Terminal is located.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Country"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="countries"
                label="Choose Country"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                v-model="country"
                @change="getState()"
                outlined
              ></v-select>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">State</p>
            <p class="label-info">Choose the State the Terminal is located.</p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="State"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-select
                :items="states"
                label="Choose State"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                v-model="state"
                :disabled="getStatesLoading"
                @change="getCity()"
                outlined
                v-if="states.length > 0"
              ></v-select>
              <v-text-field
                placeholder="State"
                solo
                hide-details
                type="text"
                v-model="state"
                required
                class="tw-mt-2"
                v-else
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">City</p>
            <p class="label-info">Choose the City the Terminal is located.</p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="City/Town"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="tw-pt-1 mb-1 mt-2" :class="classes">
              <v-select
                :items="cities"
                append-icon="mdi-chevron-down"
                v-model="city"
                :disabled="getCitiesLoading"
                @change="getCity()"
                outlined
                v-if="cities.length > 0"
              ></v-select>
              <v-text-field
                append-icon="mdi-chevron-down"
                placeholder="City or Town"
                solo
                hide-details
                type="text"
                v-model="city"
                required
                class="tw-mt-2"
                v-else
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Street Name</p>
            <p class="label-info">
              Choose the Street Name in which the Terminal is located.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Street's Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="Borno Way"
                solo
                hide-details
                type="text"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5">
        <v-col sm="4" class="">
          <div class="form-label-div">
            <p class="label">Street Number</p>
            <p class="label-info">
              Street Number in which the Passesnger is located.
            </p>
          </div>
        </v-col>
        <v-col sm="8" class="">
          <ValidationProvider
            name="Street Number"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="20"
                solo
                hide-details
                type="text"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <div
        class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"
      >
        <button class="first-btn">
          Cancel
        </button>
        <button class="second-btn" @click="showModal">
          Done
        </button>
      </div>
    </v-col>
    <booking-modal
      :dialog="dialog"
      @close="closeModal"
      @bookingAction="enterBookingCode"
      :doneLoading="deleteLoading"
    />
    <modal
      :dialog="showDialog"
      @close="closeDefaultModal"
      :color="companyColor"
      title="Great!"
      description="Your Booking was successful. 
Kindly download your ticket below."
      icon="thumbs-up"
      bottomText="Click here to download"
      downloadUrl="https://facebook.com"
    />
  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
  uploadToCloudinary,
} from "../../../services/api/APIService";
import BookingModal from "../../reuseables/BookingCodeModal";
import Modal from "../../reuseables/Modal";
export default {
  name: "EditBooking",
  components: { BookingModal, Modal },
  data() {
    return {
      deleteLoading: false,
      country: "",
      dialog: false,
      showDialog: false,
      state: "",
      city: "",
      streetNumber: "",
      streetName: "",
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      companyColor: "#004AAD",
      states: [],
      cities: [],
      genders: [
        { id: 1, name: "male" },
        { id: 2, name: "female" },
      ],
      gender: "",
      logoImage: "",
      logoImageUrl: "",
      logoImageLoading: false,
      image: "",
    };
  },
  methods: {
    showModal() {
      this.showDialog = true;
    },
    showBookingCodeModal() {
      this.dialog = true;
    },
    enterBookingCode() {
      console.log("chill is here");
    },
    closeModal() {
      this.dialog = !this.dialog;
    },
    closeDefaultModal() {
      this.showDialog = !this.showDialog;
    },
    selectTab() {
      this.$store.dispatch(
        "bookingComponent/setCurrentComponent",
        "allBookings"
      );
      sessionStorage.setItem("bookingComponent", "allBookings");
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          console.log(response);
          this.logoImageUrl = response.data.url;
          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },

    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        console.log(response);
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
        this.getCountries = false;
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.country,
        };
        console.log(data);
        const response = await getAllStatesByCountry(data);
        console.log(response);
        this.states = response.data.data.states;
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {
      try {
        this.getCitiesLoading = true;
        let data = {
          country: this.country,
          state: this.state,
        };

        const response = await getAllCitiesInAState(data);
        console.log(response);
        this.cities = response.data.data;
        this.getCitiesLoading = false;
      } catch (err) {
        console.log(err.response);
        this.cities = [];
        this.getCitiesLoading = false;
      }
    },
  },
  mounted() {
    this.getCountries();
  },
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}
.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.booking-btn {
  /* border: none !important; */
  border: 1px solid rgba(0, 74, 173, 1);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  border-radius: 6px;
  padding: 10px 25.5px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
