<template>
<div class="text-uppercase" :class="[avatarState? 'avatar ': 'avatar-inActive']"
     :style="{background:avatarState ? color:null,
      border:avatarState ? null:'1px solid'+color,
      color:avatarState ? null: color }">{{avatar}}</div>
</template>

<script>
export default {
  name: "AvatarProgress",
  props:{
    avatar : [String],
    color:{
      type: [String],
      default: "#0044AD",
    },
    avatarState:{
      type:[Boolean],
      default:false
    }
  }
}
</script>

<style scoped lang="scss">
.avatar{
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.avatar-inActive{
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
}
</style>