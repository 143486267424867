<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import TerminalDashboard from "../terminal/ViewTerminalDashboard.vue";
import AllBookings from "./AllBookingDashboard.vue";
import SingleBooking from "./SingleBooking.vue";
import EditBooking from "./EditBooking";
import AddBooking from "./AddBooking";
import TravellerDetails from "./TravellerDetails.vue";
import NextOfKinBookingDetail from "./NextOfKinBookingDetail";
import { mapGetters } from "vuex";

export default {
  name: "ViewBookingRoute",
  computed: {
    ...mapGetters("bookingComponent", ["currentComponent"])
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "terminalDashboard") {
        return TerminalDashboard;
      } else if (component === "allBookings") {
        return AllBookings;
      } else if (component === "singleBooking") {
        return SingleBooking;
      } else if (component === "editBooking") {
        return EditBooking;
      } else if (component === "addBooking") {
        return AddBooking;
      }
      else if (component === "TravellerDetails") {
        return TravellerDetails;
      }
      else if (component === "nextOfKinBookingDetail"){
        return NextOfKinBookingDetail
      }
    }
  },
  created() {
    let component = sessionStorage.getItem("bookingComponent");

    if (!component) {
      sessionStorage.setItem("bookingComponent", "allBookings");
      this.$store.dispatch("bookingComponent/setCurrentComponent", "allBookings");
      this.displayTerminalComponent()

    } else {
      this.$store.dispatch("bookingComponent/setCurrentComponent", component);
      this.displayTerminalComponent()
    }
  }
};
</script>

<style lang="scss" scoped></style>
