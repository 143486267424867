<template>
  <v-col sm="12">
    <button
        class="tw-flex tw-items-center tw-mt-6 overview-btn tw-mt-20 lg:tw-mt-0"
        @click="back"
        :style="{ color: companyColor }">
      <icons name="arrow-back" :color="companyColor" />
      <span class="tw-ml-4"> Passenger Booking Details</span>
    </button>

    <div class="tw-flex tw-flex-row tw-items-center tw-mt-10 tw-w-full">
      <v-divider light  />
      <avatar-progress class="tw-ml-3" avatar="1"  :color="companyColor" />
      <avatar-progress class="tw-mx-3" avatar="2"   :color="companyColor" />
      <avatar-progress avatar="3" :avatar-state="true"  :color="companyColor" />

    </div>

    <v-col sm="12" class="tw-bg-white tw-mt-9 tw-rounded-xl tw-px-5 lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(proceedToDone)">
      <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10">
        <p class="edit-header" :style="{color:companyColor}" >Next of Kin</p>
      </div>

      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">Relationship</p>
            <p class="label-info">
              Relationship with the Passenger.
            </p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <ValidationProvider
              name="Relationship"
              rules="required"
              v-slot="{ classes, errors }">
            <div class="" :class="classes">
              <v-combobox
                  :items="relationships"
                  placeholder="Choose Relationship"
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  item-value="name"
                  v-model="passengerDetail.relationship"
                  solo
                  hide-details
              ></v-combobox>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">First Name</p>
            <p class="label-info">Type the First Name of the Next of Kin</p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <ValidationProvider
            name="First Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="Mason"
                solo
                hide-details
                type="text"
                required
                v-model="passengerDetail.nextOfKinFirstName"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">Last Name</p>
            <p class="label-info">
              Type the Last Name of the Next of Kin
            </p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <ValidationProvider
            name="Last Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="Ryan"
                solo
                hide-details
                type="text"
                required
                v-model="passengerDetail.nextOfKinLastName"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

          <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
            <v-col sm="12" md="4" class="">
              <div class="form-label-div">
                <p class="label">Gender</p>
                <p class="label-info">
                  Choose Next of Kin Gender.
                </p>
              </div>
            </v-col>
            <v-col sm="12" md="8" class="">
              <ValidationProvider
                  name="Gender"
                  rules="required"
                  v-slot="{ classes, errors }">
                <div class="" :class="classes">
                  <v-combobox
                      :items="genders"
                      placeholder="Choose Gender"
                      append-icon="mdi-chevron-down"
                      item-text="name"
                      item-value="name"
                      v-model="passengerDetail.nextOfKinGender"
                      solo
                      hide-details
                  ></v-combobox>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">Email</p>
            <p class="label-info">
              Type the Email Address of the Next of Kin
            </p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <ValidationProvider
            name="Email"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="ryan@gmail.com"
                solo
                v-model="passengerDetail.nextOfKinEmail"
                hide-details
                type="email"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">Mobile</p>
            <p class="label-info">
              Type the Mobile Number of the Next of Kin
            </p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <phone-number :phone-number-exist="passengerDetail.nextOfKinPhoneNumber" @getNumber="getNumber"/>
        </v-col>
      </v-row>
      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">Country</p>
            <p class="label-info">
              Choose the Country of the Next of Kin.
            </p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <ValidationProvider
            name="Country"
            rules="required"
            v-slot="{ classes, errors }">
            <div class="" :class="classes">
              <v-combobox
                :items="countries"
                placeholder="Choose Country"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                v-model="passengerDetail.nextOfKinAddress.country"
                @change="getState()"
                solo
                hide-details
              ></v-combobox>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">State</p>
            <p class="label-info">Choose the State of the Next of Kin.</p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <ValidationProvider
            name="State"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-combobox
                :items="states"
                placeholder="Choose State"
                append-icon="mdi-chevron-down"
                item-text="name"
                item-value="name"
                v-model="passengerDetail.nextOfKinAddress.state"
                @change="getCity()"
                solo
                hide-details
              ></v-combobox>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">City</p>
            <p class="label-info">Choose the City of the Next of Kin.</p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <ValidationProvider
            name="City/Town"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="tw-pt-1 mb-1 mt-2" :class="classes">
              <v-combobox
                :items="cities"
                append-icon="mdi-chevron-down"
                v-model="passengerDetail.nextOfKinAddress.city"
                @change="getCity()"
                solo
                hide-details
                placeholder="Choose City"
              ></v-combobox>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">Street Name</p>
            <p class="label-info">
              Type the Street Name of the Next of Kin .
            </p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <ValidationProvider
            name="Street's Name"
            rules="required"
            v-slot="{ classes, errors }">
            <div class="" :class="classes">
              <v-text-field
                placeholder="Borno Way"
                solo
                hide-details
                type="text"
                required
                v-model="passengerDetail.nextOfKinAddress.streetName"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex-col lg:tw-flex-row">
        <v-col sm="12" md="4" class="">
          <div class="form-label-div">
            <p class="label">House Number</p>
            <p class="label-info">
              Type the House Number of the Next of Kin.
            </p>
          </div>
        </v-col>
        <v-col sm="12" md="8" class="">
          <ValidationProvider
            name="House Number"
            rules="required"
            v-slot="{ classes, errors }">
            <div class="" :class="classes">
              <v-text-field
                placeholder="20"
                solo
                hide-details
                type="Number"
                required
                v-model="passengerDetail.nextOfKinAddress.houseNumber"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <div class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10">
        <v-btn
            @click="backToTrip"
            text
            class="first-btn"
            :style="{color: companyColor, border: '1px solid' + companyColor,}">
               Cancel
          </v-btn>
          <v-btn
            text
            class="second-btn"
            type="submit"
            :style="{ background: companyColor }"
            :loading="doneLoading">
          Done
        </v-btn>
      </div>
        </form>
      </validation-observer>
    </v-col>

    <modal
      :dialog="showDialog"
      @close="showModal"
      :color="companyColor"
      title="Great!"
      description="Customer Booking was successful.
                  A copy of the reciept have been sent to your company email and customer email"
      icon="thumbs-up"
      additional-text="Thank you"
    />
  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
    addBooking,
} from "../../../services/api/APIService";
import Modal from "../../reuseables/Modal";
import Icons from "../../reuseables/Icons";
import Lga from "../../../assets/lga";
import AvatarProgress from "../../reuseables/AvatarProgress";
import PhoneNumber from "../../reuseables/PhoneNumber";
export default {
  name: "NextOfKinBookingDetail",
  components: {PhoneNumber, AvatarProgress, Icons, Modal },
  data() {
    return {
      deleteLoading: false,
      country: "",
      showDialog: false,
      state: "",
      city: "",
      streetNumber: "",
      streetName: "",
      getCountriesLoading: false,
      getStatesLoading: false,
      email: "",
      getCitiesLoading: false,
      countries: [],
      companyColor: "#004AAD",
      states: [],
      cities: [],
      relationships:["Brother","Sister","Daughter","Son","Niece","Nephew","Father","Mother",
        "Aunt","Uncle","Relative","Husband","Wife"],
      genders:["male","female"],
      relationship: "",
      companyData:{},
      passengerDetail:{
        nextOfKinAddress: {}
      },
      staffData:{},
      doneLoading: false
    };
  },
  methods: {
    showModal() {
      if (this.showDialog === true){
        this.backToTrip()
      }
      this.showDialog = !this.showDialog;
    },
  async  proceedToDone(){
      if (this.validatePhone(this.passengerDetail.nextOfKinPhoneNumber) && this.validEmail(this.passengerDetail.nextOfKinEmail)){
        this.doneLoading = true
        let data = {}
        if(this.passengerDetail.address.country.name){
          this.passengerDetail.address.country = this.passengerDetail.address.country.name
        }
        if(this.passengerDetail.address.state.name){
          this.passengerDetail.address.state = this.passengerDetail.address.state.name
        }

        if(this.passengerDetail.nextOfKinAddress.country.name){
          this.passengerDetail.nextOfKinAddress.country = this.passengerDetail.nextOfKinAddress.country.name
        }
        if(this.passengerDetail.nextOfKinAddress.state.name){
          this.passengerDetail.nextOfKinAddress.state = this.passengerDetail.nextOfKinAddress.state.name
        }
        data.travellerDetail = this.passengerDetail
        data.selectedSeat = this.passengerDetail.seats
        data.tripFare = this.passengerDetail.tripFare
        data.tripId = this.passengerDetail.tripId
        if (Object.keys(this.staffData).length){
          data.bookedBy = this.staffData.firstname
          data.bookedByTerminal = this.staffData.terminalAssignedName
        }
        else {
          data.bookedBy = "Head Office"
          data.bookedByTerminal = "Head Office"
        }

        console.log(data)
        await addBooking(data).then(()=>{
          this.doneLoading = false
          sessionStorage.removeItem("passengerDetail")
          this.showModal()
        })
            .catch((err) => {
              this.doneLoading = false;
              console.log(err.response);
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });
            });
      }

    },
    closeDefaultModal() {
      this.showDialog = !this.showDialog;
    },
    back() {
      this.$store.dispatch(
        "bookingComponent/setCurrentComponent",
        "addPassengerBookingDetail"
      );
      sessionStorage.setItem("bookingComponent", "addPassengerBookingDetail");
    },

    backToTrip(){
      this.$store.dispatch(
          "bookingComponent/setCurrentComponent",
          "addBooking"
      );
      sessionStorage.setItem("bookingComponent", "addBooking");
    },

    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        console.log(response);
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.passengerDetail.nextOfKinAddress.country.name,
        };
        const response = await getAllStatesByCountry(data);
        this.states = response.data.data.states;
        if (this.passengerDetail.nextOfKinAddress.country.name.toLowerCase() === "nigeria") {
          this.states.push({name: 'Rivers State'})
        }
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {
      if (this.passengerDetail.nextOfKinAddress.country.name.toLowerCase() === "nigeria") {
        this.cities = Lga[this.passengerDetail.nextOfKinAddress.state.name]
      }
      else {
        try {
          this.getCitiesLoading = true;
          let data = {
            country: this.country,
            state: this.state,
          };
          if (this.passengerDetail.nextOfKinAddress.country.name) {
            data.country = this.passengerDetail.nextOfKinAddress.country.name
          }
          if (this.passengerDetail.nextOfKinAddress.state.name) {
            data.state = this.passengerDetail.nextOfKinAddress.state.name
          }
          const response = await getAllCitiesInAState(data);
          console.log(response);
          this.cities = response.data.data;
          this.getCitiesLoading = false;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }
      }
    },
    validatePhone(phoneNumber){
      // let num = /^\(?([0-9]{3})\)?([0-9]{3})?([0-9]{3})?([0-9]{4})$/;
      if(phoneNumber.length === 13) {
        return true;
      }
      else {
        this.$displaySnackbar({
          message: "Phone number not valid e.g 2348000000000",
          success: false,
        });
        return false;
      }

    },
    validEmail(email){
      let valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (email.match(valid)){
        return true
      }
      else {
        this.$displaySnackbar({
          message: "Email not valid e.g example@company.com",
          success: false,
        });
        return false;
      }
    },
    getNumber(number){
      this.passengerDetail.nextOfKinPhoneNumber= number
    }
  },
  mounted() {
    this.getCountries();
  },
  created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let staffData = JSON.parse(sessionStorage.getItem("staffData"))
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    let storeData = JSON.parse(sessionStorage.getItem("passengerDetail"))
    if (storeData){
      Object.assign(this.passengerDetail,storeData)
    }
    if (staffData){
      this.staffData = staffData
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter", serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}
.action-btn-div {
  font-family: "Inter",serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter",sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0;
  color: rgba(0, 74, 173, 1);
}
.booking-btn {
  /* border: none !important; */
  border: 1px solid rgba(0, 74, 173, 1);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  border-radius: 6px;
  padding: 10px 25.5px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
