<template>
  <div @click="handleSelectedSeat"  class="seat " :style="{backgroundImage: 'url(' +getSeatBackground+ ')'}"
        :class="[seatStatus === 'BOOKED' || seatStatus === 'PENDING' ? 'tw-cursor-not-allowed': 'tw-cursor-pointer']">
    <v-card class="" :disabled="seatStatus=== 'BOOKED'" flat
            style="background: none; border: none; color: #FFFFFF"  >
      {{seatNumber}}
    </v-card>
  </div>
</template>

<script>
import availableSeat from '@/assets/bookedbg.svg'
import selectedSeat from '@/assets/selectedbg.svg'
import bookedSeat from '@/assets/availablebg.svg'
import pendingSeat from '@/assets/pendingSeatBg.svg'
import {mapGetters} from "vuex";

export default {
  name: "Seat",
  props:{
    seatNumber: String,
    seatStatus: String
  },
  data(){
    return{
      bookedSeat,
      selectedSeat,
      availableSeat,
      currentStatus : this.seatStatus
    }
  },

  computed:{
    ...mapGetters('seatsSelection',['seats']),
    getSeatBackground(){
      let background = availableSeat

        if (this.currentStatus === 'AVAILABLE' ){
              background = availableSeat
            }
            else if (this.currentStatus === 'SELECTED'){
              background = selectedSeat
            }
            else if (this.currentStatus === 'PENDING'){
              background = pendingSeat
            }
            else if (this.currentStatus === 'BOOKED'){
              background =  bookedSeat
            }


        // this.seats && this.seats.forEach(currentSeat => {
        //
        //   if (currentSeat.seatStatus === 'AVAILABLE' && this.seatNumber === currentSeat.seatNumber){
        //     background = availableSeat
        //   }
        //   else if (currentSeat.seatStatus === 'SELECTED' && this.seatNumber === currentSeat.seatNumber){
        //     background = selectedSeat
        //   }
        //   else if (currentSeat.seatStatus === 'PENDING'&& this.seatNumber === currentSeat.seatNumber){
        //     background = pendingSeat
        //   }
        //   else if (currentSeat.currentStatus === 'BOOKED'){
        //     background =  bookedSeat
        //   }

        // })

      return background
    }
  },
  methods:{
    handleSelectedSeat(){
     if (this.currentStatus === 'AVAILABLE' || this.currentStatus === 'SELECTED'){
       let seat ={}
       if (this.currentStatus === 'AVAILABLE'){
         this.currentStatus = 'SELECTED'
         seat.seatNumber = this.seatNumber
         seat.seatStatus = this.currentStatus
         seat.selectedBy = this.generateRandomString()
         seat.travellerDetail={}
         this.$store.dispatch('seatsSelection/addSeat',seat)
         this.setSeatTimer(this.currentStatus,this.seats.length)
       }
       else {
         this.currentStatus = 'AVAILABLE'
         seat.seatNumber = this.seatNumber
         seat.seatStatus = this.currentStatus
         seat.selectedBy = null
         seat.travellerDetail={}
         this.$store.dispatch('seatsSelection/removeSeat',seat)
         this.setSeatTimer(this.currentStatus,this.seats.length)
       }
       this.$emit('selectedSeat',this.seats)
     }
    },
    setSeatTimer(status, seatsSize){
      if (seatsSize > 0) {
        this.$store.dispatch('seatsSelection/setOneWayBookingMinutesTimer', 9)
        this.$store.dispatch('seatsSelection/setOneWayBookingShowTimer', true)
        sessionStorage.setItem('oneWayBookingMinutesTimer', JSON.stringify(9))
        sessionStorage.setItem('oneWayBookingShowTimer', JSON.stringify(true))

      }else if(seatsSize === 0){
        this.$store.dispatch('seatsSelection/setOneWayBookingMinutesTimer', 0)
        this.$store.dispatch('seatsSelection/setOneWayBookingShowTimer', false)
        sessionStorage.removeItem('oneWayBookingMinutesTimer')
        sessionStorage.removeItem('oneWayBookingShowTimer')
      }
    },

    generateRandomString(){
      return (Math.random()).toString(36).replace("0.", "");
    }
  },
   created() {

   }
}
</script>

<style scoped lang="scss">
.seat{
  display: flex;
  height: 55px;
  width: 55px;
  align-items: center;
  justify-content: center;
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 15px;
  color: #FFFFFF;
  background-position: center;
  background-size: cover;

}
</style>