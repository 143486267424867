<template>
  <v-col sm="12" class="tw-flex tw-justify-start tw-items-start">
    <loader v-if="loading" :color="companyData.companyColor" :loading="loading"/>
    <div class="tw-w-full tw-flex tw-flex-col" v-else>
      <button
          class="tw-flex tw-items-center overview-btn tw-mt-20 lg:tw-mt-6"
          @click="backToAllBookings"
          :style="{ color: companyData.companyColor }">
        <icons name="arrow-back" :color="companyData.companyColor" />
        <span class="tw-ml-4"> Bookings</span>
      </button>

      <div class="tw-flex tw-w-full tw-justify-center tw-mt-10">
        <v-col  sm="9" class="tw-bg-white booking-card lg:tw-p-20 tw-p-5 tw-flex-col">
          <div class="tw-w-flex tw-flex tw-justify-between">
            <div class="tw-flex   tw-flex-row ">
              <h6 class="booking-card-header">
                Booking Ref ID: &nbsp;&nbsp;
              </h6>
              <h6 class="booking-card-desc  text-uppercase">
                {{currentBooking.bookingRefId}}
              </h6>
            </div>
            <div class="tw-cursor-pointer print-label"
                 @click="printTicket()">Print <v-icon :color="companyData.companyColor" >mdi-printer-outline</v-icon></div>
          </div>
        <div class="tw-w-full tw-flex-col md:tw-flex-row tw-flex">
          <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-row tw-pt-10">
            <h6 class="booking-card-header tw-w-1/2">
              Fleet
            </h6>
            <h6 class="booking-card-desc  text-uppercase tw-w-1/2">
              {{currentBooking.fleetRegistrationNumber}}
            </h6>
          </div>
          <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-row tw-pt-10">
            <h6 class="booking-card-header tw-w-1/2">
              Selected Seat(s) No
            </h6>
            <h6 class="booking-card-desc tw-w-1/2">
              {{getSelectedSeats(currentBooking.selectedSeats)}}
            </h6>
          </div>
        </div>

        <div class="tw-w-full tw-flex-col md:tw-flex-row tw-flex">
          <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-row tw-pt-10">
            <h6 class="booking-card-header tw-w-1/2">
              Departure
            </h6>
            <h6 class="booking-card-desc text-capitalize tw-w-1/2">
              {{currentBooking.departure}}
            </h6>
          </div>
          <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-row tw-pt-10">
            <h6 class="booking-card-header tw-w-1/2">
              Arrival
            </h6>
            <h6 class="booking-card-desc text-capitalize tw-w-1/2">
              {{currentBooking.arrival}}
            </h6>
          </div>
        </div>

        <div class="tw-w-full tw-flex-col lg:tw-flex-row tw-flex">
          <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-row tw-pt-10">
            <h6 class="booking-card-header tw-w-1/2">
              Trip Date
            </h6>
            <h6 class="booking-card-desc tw-w-1/2 text-capitalize">
              {{currentBooking.tripDate}}
            </h6>
          </div>
          <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-row tw-pt-10">
            <h6 class="booking-card-header tw-w-1/2">
              Time
            </h6>
            <h6 class="booking-card-desc tw-w-1/2 text-capitalize" >
              {{getTime(currentBooking.tripDate,currentBooking.tripTime)}}
            </h6>
          </div>
        </div>

        <div class="tw-w-full tw-flex-col md:tw-flex-row tw-flex">
          <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-row tw-pt-10">
            <h6 class="booking-card-header tw-w-1/2">
              Booked By
            </h6>
            <h6 class="booking-card-desc text-capitalize tw-w-1/2">
              {{currentBooking.bookedBy}}
            </h6>
          </div>
          <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-row tw-pt-10">
            <h6 class="booking-card-header tw-w-1/2 text-capitalize">
              Amount
            </h6>
            <h6 class="booking-card-desc tw-w-1/2">
              &#8358; {{currentBooking.tripFare}}
            </h6>
          </div>
        </div>

        <div class="tw-w-full tw-flex-col md:tw-flex-row tw-flex">
          <div class="tw-w-full md:tw-w-1/2  tw-flex tw-flex-row tw-pt-10">
            <h6 class="booking-card-header tw-w-1/2 ">
              Time of Booking
            </h6>
            <h6 class="booking-card-desc tw-w-1/2 text-capitalize">
              {{getDateTime(currentBooking.createdAt)}}
            </h6>
          </div>
        </div>



        <v-divider class="tw-mt-10"/>

      <div v-for="(traveller,index) in currentBooking.travellerDetails" :key="index">
        <h6 class="booking-card-header tw-py-5">Customer Details <span v-if="index > 0">{{index+1}}</span></h6>
            <avatar v-if="traveller.firstName" avatar-type="rect"
                    :avatar="traveller.firstName.charAt(0)"
                    :color="companyData.companyColor"/>
            <div class="tw-w-full tw-flex-col ">
              <div class="tw-w-full md:tw-flex-row tw-flex-col tw-flex">
                <div class="tw-flex tw-w-full md:tw-w-1/2  tw-flex-row tw-pt-10">
                  <h6 class="booking-card-header tw-w-1/2">
                    First Name
                  </h6>
                  <h6 class="booking-card-desc tw-w-1/2 text-capitalize">
                    {{traveller.firstName}}
                  </h6>
                </div>
                <div class="tw-flex tw-w-full md:tw-w-1/2  tw-flex-row tw-pt-10">
                  <h6 class="booking-card-header tw-w-1/2">
                    Last Name
                  </h6>
                  <h6 class="booking-card-desc tw-w-1/2 text-capitalize">
                    {{traveller.lastName}}
                  </h6>
                </div>
              </div>
        </div>

        <div class="tw-w-full tw-flex-col ">
          <div class="tw-w-full md:tw-flex-row tw-flex-col tw-flex">
            <div class="tw-flex tw-w-full md:tw-w-1/2  tw-flex-row tw-pt-10">
              <h6 class="booking-card-header tw-w-1/2">
                Email
              </h6>
              <h6 class="booking-card-desc tw-w-1/2 text-lowercase">
                {{traveller.email}}
              </h6>
            </div>
            <div class="tw-flex tw-w-full md:tw-w-1/2  tw-flex-row tw-pt-10">
              <h6 class="booking-card-header tw-w-1/2">
                Phone Number
              </h6>
              <h6 class="booking-card-desc tw-w-1/2 text-capitalize">
                {{traveller.phoneNumber}}
              </h6>
            </div>
          </div>

        </div>
        <h6 class="booking-card-header tw-pt-8">Next of Kin Details</h6>
        <div class="tw-w-full tw-flex-col ">
          <div class="tw-w-full md:tw-flex-row tw-flex-col tw-flex">
            <div class="tw-flex tw-w-full md:tw-w-1/2  tw-flex-row tw-pt-10">
              <h6 class="booking-card-header tw-w-1/2">
                First Name
              </h6>
              <h6 class="booking-card-desc tw-w-1/2 text-capitalize">
                {{traveller.nextOfKinFirstName}}
              </h6>
            </div>
            <div class="tw-flex tw-w-full md:tw-w-1/2  tw-flex-row tw-pt-10">
              <h6 class="booking-card-header tw-w-1/2">
                Last Name
              </h6>
              <h6 class="booking-card-desc tw-w-1/2 text-capitalize">
                {{traveller.nextOfKinLastName}}
              </h6>
            </div>
          </div>

        </div>

        <div class="tw-w-full tw-flex-col ">
          <div class="tw-w-full md:tw-flex-row tw-flex-col tw-flex">
            <div class="tw-flex tw-w-full md:tw-w-1/2  tw-flex-row tw-pt-10">
              <h6 class="booking-card-header tw-w-1/2">
                Email
              </h6>
              <h6 class="booking-card-desc tw-w-1/2 text-lowercase">
                {{traveller.nextOfKinEmail}}
              </h6>
            </div>
            <div class="tw-flex tw-w-full md:tw-w-1/2  tw-flex-row tw-pt-10">
              <h6 class="booking-card-header tw-w-1/2">
                Phone Number
              </h6>
              <h6 class="booking-card-desc tw-w-1/2 text-capitalize">
                {{traveller.nextOfKinPhoneNumber}}
              </h6>
            </div>
          </div>

        </div>

        <v-divider class="tw-mt-10"/>
        </div>

        </v-col>

      </div>
    </div>

      <section slot="pdf-content" v-if="isPrinting">
        <booking-receipt  :booking="currentBooking" ref="customerReceipt" />
      </section>
  </v-col>
</template>

<script>
import Loader from "../../reuseables/Loader";
import Icons from "../../reuseables/Icons";
import Avatar from "../../reuseables/Avatar";
import dayjs from "dayjs";

import BookingReceipt from "@/components/transportCompany/booking/BookingReceipt.vue";
export default {
  name: "SingleBooking",
  components: {BookingReceipt, Avatar, Icons, Loader,},
  data() {
    return {
      companyData:{},
      loading: false,
      currentBooking:{},
      isPrinting:false
    };
  },
  methods: {
    backToAllBookings() {
      this.$store.dispatch(
        "bookingComponent/setCurrentComponent",
        "allBookings"
      );
      sessionStorage.setItem("bookingComponent", "allBookings");
    },
    selectEditTab() {
      this.$store.dispatch(
        "bookingComponent/setCurrentComponent",
        "editBooking"
      );
      sessionStorage.setItem("bookingComponent", "editBooking");
    },
    getSelectedSeats(seats){

      let returnSeats = ""
      seats.forEach((seat,index) =>{
        returnSeats += seat.seatNumber
        if (index+1 !== seats.length){
          returnSeats += ", "
        }
      })
      return returnSeats
    },
    getTime(date,time){
      return dayjs(date+time).format('LT')
    },
    getDateTime(date){
      return dayjs(date).format('lll')
    },
    toSentenceCase(text){
      text = text.toLowerCase()
      return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
    },
    printBookingReceipt(){
      this.isPrinting = !this.isPrinting
      this.$refs.customerReceipt.print()
    },
    printTicket() {
      if (window.Android){
        let data = {}
        data.companyName = this.companyData.companyName
        data.companyLogo = this.companyData.companyLogo
        data.companyEmail = this.companyData.secondaryEmail
        data.companyPhoneNumber = this.companyData.firstContactPhoneNumber
        data.bookingRefId = this.currentBooking.bookingRefId
        data.selectedSeats = this.getSelectedSeats(this.currentBooking.selectedSeats)
        data.tripDate = this.currentBooking.tripDate
        data.tripTime = this.currentBooking.tripTime
        data.departure = this.currentBooking.departure
        data.arrival = this.currentBooking.arrival
        data.bookedBy = this.currentBooking.bookedBy
        data.bookedAt = this.currentBooking.createdAt
        data.totalAmount = this.currentBooking.tripFare
        data.departureAddress = this.currentBooking.departureTerminalAddress
        data.customerFirstName = this.currentBooking.travellerDetails[0].firstName
        data.customerLastName = this.currentBooking.travellerDetails[0].lastName

        window.Android.printTicket(JSON.stringify(data));
      }
      else {
        this.printBookingReceipt()
      }

    }
  },
  created() {
    this.loading = false
    let companyData = JSON.parse(sessionStorage.getItem("companyData"))
    if (companyData){
      this.companyData = companyData
    }
    let currentBooking = JSON.parse(sessionStorage.getItem("currentBooking"))
    if (currentBooking){
      this.currentBooking = currentBooking
      console.log(this.currentBooking.travellerDetails)
    }
  }
};
</script>

<style lang="scss" scoped>
.booking-card{
  width: 813px;
  min-height: 710px;
  height: max-content;
  border-radius: 10px;
}
.booking-card-header{
  font-family: Poppins,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #4F4F4F;
  @media screen and (max-width: 768px){
    font-size: 14px;
  }
}
.booking-card-desc{
  font-family: Inter,sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #4F4F4F;

  @media screen and (max-width: 768px){
    font-size: 14px;
  }
  //text-transform: capitalize !important;
}

.print-label{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}
</style>
