<template>
  <div class="tw-flex tw-w-full tw-min-h-screen tw-flex-col tw-bg-white">
  <div class="tw-flex tw-w-full  tw-flex-col lg:tw-px-20 tw-px-4">

  <div class="tw-flex tw-w-full tw-flex-col tw-py-8" >
    <div class="tw-flex tw-w-full look">
      <div @click="routeBack" class="tw-flex tw-items-center tw-justify-start back" id="routeBackDiv">
      <v-icon>mdi-chevron-left</v-icon>Back
      </div>
    </div>
    <div class="tw-flex tw-w-full tw-justify-end look">
      <seat-timer/>
    </div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(proceedToSubmit)">
    <div class="tw-flex tw-w-full tw-flex-col" v-for="(seat,index) in passengerDetail.seats" :key="index" >
      <customer-details :traveller-detail="seat.travellerDetail" :index="index"/>
      <v-divider/>
    </div>
     <div class="tw-flex tw-w-full tw-justify-end tw-mt-5">
       <base-button button-text="Submit" :loading="loading" :color="companyData.companyColor" />
     </div>
      </form>
    </validation-observer>
  </div>
</div>
    <modal
        :dialog="showBookingSuccessful"
        @close="handleShowBookingSuccessful"
        :color="companyData.companyColor"
        title="Great!"
        description="Customer Booking was successful.
                  A copy of the receipt have been sent to your customer email"
        icon="thumbs-up"
        additional-text="Thank you"
        section="print-ticket"
        @printTicket="printTicket"
    />

    <section slot="pdf-content" v-if="isPrinting">
      <booking-receipt  :booking="currentBooking" ref="customerReceipt" />

    </section>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import CustomerDetails from "./CustomerDetails.vue";
import BaseButton from "@/components/reuseables/BaseButton";
import Modal from "@/components/reuseables/Modal";
import {addBooking} from "@/services/api/APIService";
import SeatTimer from "@/components/transportCompany/booking/SeatTimer";
import BookingReceipt from "@/components/transportCompany/booking/BookingReceipt.vue";


export default {
  name: "TravellerDetails",
  components: {BookingReceipt, SeatTimer, Modal, BaseButton, CustomerDetails},
  data(){
    return{
      passengerDetail:{},
      staffData:{},
      showBookingSuccessful : false,
      loading : false,
      isPrinting: false,
      currentBooking:{}
    }
  },
  computed:{
    ...mapGetters("tripAndTravellerDetails",["tripAndTravellerDetails"]),
    ...mapGetters("companyProfile", ['companyData'])
  },

  methods:{
    scrollTo(name) {
      const el = document.getElementById(name);
      el.scrollIntoView({ behavior: "smooth" });
    },
    routeBack(){
      this.$store.dispatch("bookingComponent/setCurrentComponent", "addBooking");
       sessionStorage.setItem("bookingComponent", "addBooking");
    },
    handleShowBookingSuccessful(){
      if(this.showBookingSuccessful){
        sessionStorage.removeItem('oneWayBookingMinutesTimer')
        sessionStorage.removeItem('oneWayBookingShowTimer')
        sessionStorage.removeItem('currentSelectedSeats')
        this.$store.dispatch("seatsSelection/setOneWayBookingShowTimer",false)
        this.routeBack()
      }
      this.showBookingSuccessful = !this.showBookingSuccessful
    },
    proceedToSubmit(){
      this.loading = true
      let data = {}
      if (Object.keys(this.staffData).length){
        data.bookedBy = this.staffData.firstname +" "+ this.staffData.terminalAssignedName
        data.bookedByTerminal = this.staffData.terminalAssignedName
      }
      else {
        data.bookedBy = "Head Office"
        data.bookedByTerminal = "Head Office"
      }
      data.selectedSeats = []
      data.travellerDetails=[]
      data.tripFare = (Number.parseFloat(this.tripAndTravellerDetails.tripFare) * this.tripAndTravellerDetails.seats.length) * 100
      data.tripId = this.tripAndTravellerDetails.tripId
      data.domain = window.location.hostname
      this.tripAndTravellerDetails.seats.forEach(seat =>{
        let singleSeat = {}
        singleSeat.seatNumber = seat.seatNumber
        singleSeat.selectedBy= seat.selectedBy
        data.selectedSeats.push(singleSeat)
        data.travellerDetails.push(seat.travellerDetail)

      })
      addBooking(data).then(res =>{
        this.loading = false
        sessionStorage.removeItem("primaryTraveller")
        sessionStorage.removeItem("passengerDetail")
        sessionStorage.removeItem("tripDetails")
        sessionStorage.removeItem("currentSelectedSeats")
        sessionStorage.removeItem("oneWaySeats")
        this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",{})
        res.data.tripFare = res.data.tripFare * 100
        this.currentBooking= res.data

        this.handleShowBookingSuccessful()
      }).catch((err) => {
        this.loading = false;
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });

    },
    handlePrintBooking(){
      this.isPrinting = !this.isPrinting
      this.$refs.customerReceipt.print()
    },
    printTicket() {
      if (window.Android){
        let data = {}
        data.companyName = this.companyData.companyName
        data.companyLogo = this.companyData.companyLogo
        data.companyEmail = this.companyData.secondaryEmail
        data.companyPhoneNumber = this.companyData.firstContactPhoneNumber
        data.bookingRefId = this.currentBooking.bookingRefId
        data.selectedSeats = this.getSelectedSeats(this.currentBooking.selectedSeats)
        data.tripDate = this.currentBooking.tripDate
        data.tripTime = this.currentBooking.tripTime
        data.departure = this.currentBooking.departure
        data.arrival = this.currentBooking.arrival
        data.bookedBy = this.currentBooking.bookedBy
        data.bookedAt = this.currentBooking.createdAt
        data.totalAmount = this.currentBooking.tripFare / 10
        data.departureAddress = this.currentBooking.departureTerminalAddress
        data.customerFirstName = this.currentBooking.travellerDetails[0].firstName
        data.customerLastName = this.currentBooking.travellerDetails[0].lastName

        window.Android.printTicket(JSON.stringify(data));
      }
      else {
        this.handlePrintBooking()
      }
    },
    getSelectedSeats(seats){
      let returnSeats = ""
      seats.forEach((seat,index) =>{
        returnSeats += seat.seatNumber
        if (index+1 !== seats.length){
          returnSeats += ", "
        }
      })
      return returnSeats
    },
  },
  mounted() {
    this.scrollTo("routeBackDiv")
  },
  created() {
    this.passengerDetail = JSON.parse(sessionStorage.getItem("passengerDetail"))
    let staffData = JSON.parse(sessionStorage.getItem("staffData"))
    if (Object.keys(this.passengerDetail).length){
      this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",this.passengerDetail)
    }
    if (staffData){
      this.staffData = staffData
    }
  }
}
</script>

<style scoped lang="scss">
.section-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #4F4F4F;
}

.passenger{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

}

.use-code{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;


}
.or{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

}

.back{
  display: flex;
  align-items: center;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
  width: max-content;
  text-transform: none;
  cursor: pointer;
}

.look{
  padding: 0 7%;

  @media screen and (max-width: 820px) {
    padding: 0.5rem;
  }
}
</style>