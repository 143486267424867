<template>
  <v-col sm="12">
    <loader
      :color="companyData.companyColor"
      :loading="loading"
      v-if="loading"
    />
    <v-col sm="12" class="tw-p-0 tw-flex-col" v-else>
      <div
        class="
          tw-flex tw-w-full tw-flex-col
          lg:tw-flex-row lg:tw-justify-between lg:tw-pb-5
          lg:tw-mt-0
          tw-mt-5
          table-container-div
          tw-p-0
        "
      >
        <icon-and-text
          :icon="addbooking"
          title="New Booking "
          desc="Add a Booking"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '25%'"
          @clickAction="newBookingTab"
        />
        <icon-and-text
          :icon="allbookings"
          :title="bookings.length.toString()"
          desc="All Bookings"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '25%'"
        />
        <image-and-text-two
          :showImage="false"
          :title="
            mostRecent && mostRecent.travellerDetails
              ? mostRecent.travellerDetails[0].firstName +
                ' ' +
                mostRecent.travellerDetails[0].lastName
              : 'Nil'
          "
          labelOne="Email : "
          labelTwo="Mobile : "
          :valueOne="
            mostRecent && mostRecent.travellerDetails
              ? mostRecent.travellerDetails[0].email
              : 'Nil'
          "
          :valueTwo="
            mostRecent && mostRecent.travellerDetails
              ? '+' + mostRecent.travellerDetails[0].phoneNumber
              : 'Nil'
          "
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '48%'"
          :height="$vuetify.breakpoint.mdAndUp ? '100px' : 'auto'"
        />
      </div>

      <div
        class="
          tw-flex tw-w-full tw-flex-col
          lg:tw-flex-row lg:tw-justify-between lg:tw-py-5
          tw-mt-20
          lg:tw-mt-5
          table-container-div
          tw-p-5
          lg:tw-pl-8
        "
        style="background: white"
      >
        <div class="table-container lg:tw-pr-8">
          <div
            class="
              tw-flex tw-flex-col
              lg:tw-flex-row lg:tw-justify-between lg:tw-items-center
            "
          >
            <p
              class="table-header"
              :style="{ color: companyData.companyColor }"
            >
              All Bookings
            </p>
            <div
              class="
                tw-flex tw-flex-row tw-justify-end
                lg:tw-justify-center
                tw-items-center
              "
            >
              <div
                class="mr-2"
                style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
              >
                <v-text-field
                  placeholder="Enter Booking Ref Id"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
                >
                  <template #prepend>
                    <v-icon :color="companyData.companyColor" class="ml-5">
                      mdi-magnify
                    </v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="companyData.companyColor">
                      mdi-filter-variant
                    </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            item-key="id"
            :single-select="singleSelect"
            height="available"
            :hide-default-footer="bookings.length <= 10"
            :items="filterMatchedBookings"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyData.companyColor"
            @click:row="handleViewABooking"
          >
            <template #header.sno>
              <span class="status">S/N</span>
            </template>
            <template v-slot:[`item.sno`]="{ item }">
              <span class="td-style">{{ item.sno }}</span>
            </template>
            <template #header.firstName>
              <span class="status">First Name</span>
            </template>
            <template v-slot:[`item.firstName`]="{ item }">
              <span class="td-style text-capitalize">{{
                getFirstName(item)
              }}</span>
            </template>
            <template #header.lastName>
              <span class="status">Last Name</span>
            </template>
            <template v-slot:[`item.lastName`]="{ item }">
              <span class="td-style text-capitalize">{{
                getLastName(item)
              }}</span>
            </template>
            <template #header.bookingRefId>
              <span class="status">Booking Ref ID</span>
            </template>
            <template v-slot:[`item.bookingRefId`]="{ item }">
              <span class="td-style text-uppercase">{{
                item.bookingRefId
              }}</span>
            </template>
            <template #header.departure>
              <span class="status">Departure</span>
            </template>
            <template v-slot:[`item.departure`]="{ item }">
              <span class="td-style text-capitalize">{{ item.departure }}</span>
            </template>
            <template #header.arrival>
              <span class="status">Arrival</span>
            </template>
            <template v-slot:[`item.arrival`]="{ item }">
              <span class="td-style text-capitalize">{{ item.arrival }}</span>
            </template>
            <template #header.fleetRegistrationNumber>
              <span class="status">Fleet</span>
            </template>
            <template v-slot:[`item.fleetRegistrationNumber`]="{ item }">
              <span class="td-style text-capitalize">{{
                item.fleetRegistrationNumber
              }}</span>
            </template>
            <template #header.tripDate>
              <span class="status">Trip Date</span>
            </template>
            <template v-slot:[`item.tripDate`]="{ item }">
              <span class="td-style text-capitalize">{{ item.tripDate }}</span>
            </template>
            <template #header.tripTime>
              <span class="status">Trip Time</span>
            </template>
            <template v-slot:[`item.tripTime`]="{ item }">
              <span class="td-style text-capitalize">{{
                getTime(item.tripDate, item.tripTime)
              }}</span>
            </template>
            <template #header.bookingType>
              <span class="status">Booking Type</span>
            </template>
            <template v-slot:[`item.bookingType`]="{ item }">
              <span class="td-style text-capitalize">{{ item.bookingType }}</span>
            </template>
            <template #header.bookedBy>
              <span class="status">Booked By</span>
            </template>
            <template v-slot:[`item.bookedBy`]="{ item }">
              <span class="td-style text-capitalize">{{ item.bookedBy }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="companyData.companyColor"
                        >mdi-dots-horizontal</v-icon
                      >
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer"
                      @click="handleViewABooking(item)"
                    >
                      <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2"
                      />
                      View
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="">
                    <v-list-item-title
                        style="color: black"
                        class="tw-flex-row tw-flex tw-cursor-pointer tw-items-center"
                        @click="printTicket(item)"
                    >
                      <v-icon :color="companyData.companyColor" >mdi-printer-outline</v-icon>
                      Print
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

          </v-data-table>
        </div>
      </div>
        <section slot="pdf-content" v-if="Object.keys(selectedBooking).length">
          <booking-receipt  :booking="selectedBooking" ref="customerReceipt" />
        </section>

    </v-col>
  </v-col>
</template>

<script>
import IconAndText from "../IconAndText";
import ImageAndTextTwo from "../ImageAndTextTwo";
import addbooking from "@/assets/icons/addbooking.png";
import allbookings from "@/assets/icons/allbookings.png";
import locationImg from "@/assets/model.jpg";
import { getAllTransportCompanyBookings } from "@/services/api/APIService";
import dayjs from "dayjs";
import Loader from "../../reuseables/Loader";
import BookingReceipt from "@/components/transportCompany/booking/BookingReceipt.vue";

export default {
  name: "AllBookingDashboard",
  components: {BookingReceipt, Loader, IconAndText, ImageAndTextTwo },
  data() {
    return {
      addbooking,
      allbookings,
      locationImg,
      headers: [
        { text: "", value: "sno" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "", value: "bookingRefId" },
        { text: "", value: "departure" },
        { text: "", value: "arrival" },
        { text: "", value: "fleetRegistrationNumber" },
        { text: "", value: "tripDate" },
        { text: "", value: "tripTime" },
        {text: "",value: "bookingType"},
        { text: "", value: "bookedBy" },
        { text: "", value: "actions", align: "end" },
      ],
      not_found: "Bookings will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      companyData: {},
      bookings: [],
      mostRecent: {},
      loading: false,
      selectedBooking:{}
    };
  },
  computed:{
    filterMatchedBookings() {
      return this.bookings.filter((booking) => {
        return !this.search || (booking.bookingRefId.toLowerCase().startsWith(this.search.toLowerCase()));
      })
    },
  },
  methods: {
    selectEditTab() {
      this.$store.dispatch(
        "bookingComponent/setCurrentComponent",
        "editBooking"
      );
      sessionStorage.setItem("bookingComponent", "editBooking");
    },
    handleViewABooking(booking) {
      this.$store.dispatch(
        "bookingComponent/setCurrentComponent",
        "singleBooking"
      );
      sessionStorage.setItem("bookingComponent", "singleBooking");
      sessionStorage.setItem("currentBooking", JSON.stringify(booking));
    },
    handlePrintBooking(booking){
      this.selectedBooking = booking
      this.$refs.customerReceipt.print()
    },
    getSelectedSeats(seats){
      let returnSeats = ""
      seats.forEach((seat,index) =>{
        returnSeats += seat.seatNumber
        if (index+1 !== seats.length){
          returnSeats += ", "
        }
      })
      return returnSeats
    },
    printTicket(booking) {
      if (window.Android){
        let data = {}
        data.companyName = this.companyData.companyName
        data.companyLogo = this.companyData.companyLogo
        data.companyEmail = this.companyData.secondaryEmail
        data.companyPhoneNumber = this.companyData.firstContactPhoneNumber
        data.bookingRefId = booking.bookingRefId
        data.selectedSeats = this.getSelectedSeats(booking.selectedSeats)
        data.tripDate = booking.tripDate
        data.tripTime = booking.tripTime
        data.departure = booking.departure
        data.arrival = booking.arrival
        data.bookedBy = booking.bookedBy
        data.bookedAt = booking.createdAt
        data.totalAmount = booking.tripFare
        data.departureAddress = booking.departureTerminalAddress
        data.customerFirstName = booking.travellerDetails[0].firstName
        data.customerLastName = booking.travellerDetails[0].lastName

        window.Android.printTicket(JSON.stringify(data));
      }
      else {
        this.handlePrintBooking(booking)
      }

    },
    newBookingTab() {

      this.$store.dispatch(
          "bookingComponent/setCurrentComponent",
          "addBooking"
      );
      sessionStorage.setItem("bookingComponent", "addBooking");

    },
    getFirstName(item) {
      if (item.travellerDetails.length) {
        return item.travellerDetails[0].firstName;
      }
      else {
        return ""
      }
    },
    getLastName(item) {
      if (item.travellerDetails.length) {
        return item.travellerDetails[0].lastName;
      }
      else {
        return ""
      }
    },
    async getAllBookings() {
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.page = 0
      data.pageSize = 200
      await getAllTransportCompanyBookings(data)
        .then((res) => {
          this.bookings = res.data;

          this.mostRecent = this.bookings[this.bookings.length - 1];
          this.bookings.sort(function (a, b) {
            return dayjs(b.createdAt).toDate() - dayjs(a.createdAt).toDate();
          });
          this.bookings.forEach((booking, index) => (booking.sno = index + 1));
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },

    getTime(date, time) {
      return dayjs(date + time).format("LT");
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    if (companyData) {
      this.companyData = companyData;
    }
    await this.getAllBookings();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.showings {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  span {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  // text-align: left;
  color: rgba(0, 74, 173, 1);
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: available;
  padding: 0 !important;
}
.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 0;
}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>
